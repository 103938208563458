export const dialogData = {
  data () {
    return {
      dialogState: false,
      submitDisabled: false,
      formValid: null,
      mode: 'create',
      id: null,
      acronym: null,
      context: null,
      loading: false,
      success: false,
      error: false
    }
  }
}

export const dialogTriggers = {
  created () {
    this.$bus.on('open:create-dialog', this.openInCreateMode)
    this.$bus.on('open:edit-dialog', this.openInEditMode)
  },
  beforeDestroy () {
    this.$bus.off('open:create-dialog', this.openInCreateMode)
    this.$bus.off('open:edit-dialog', this.openInEditMode)
  }
}

export const editEvents = {
  methods: {
    openEditDialog (dialogData) {
      this.$bus.emit('open:edit-dialog', { ...dialogData })
    }
  }
}

export const deleteEvents = {
  methods: {
    openDeleteDialog (dialogData) {
      this.$bus.emit('open:delete-dialog', { ...dialogData })
    }
  }
}
