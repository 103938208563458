<template>
  <v-row justify="center">
    <v-dialog
      :value="dialogState"
      scrollable
      fullscreen
      transition="slide-y-reverse-transition"
      @input="$emit('update:dialog-state')"
    >
      <v-card>
        <v-card-title :class="titleBackgroundClasses">
          <v-btn
            icon
            large
            :class="`mr-3 ${titleClasses}`"
            @click.native="$emit('update:dialog-state', false)"
          >
            <v-icon>
              {{ $vuetify.icons.values.back }}
            </v-icon>
          </v-btn>
          <span :class="titleClasses">
            {{ $t(i18nContext + '.create.' + title) }}
          </span>
        </v-card-title>
        <v-divider
          v-show="showDivider"
        />
        <full-screen-alert
          :show-alert="success"
          type="success"
          :text="$t(i18nContext + '.create.success')"
        />
        <full-screen-alert
          :show-alert="error"
          type="error"
          :text="$t(i18nContext + '.create.error')"
        />
        <v-card-text class="px-0">
          <slot name="form" />
        </v-card-text>
        <alert
          v-show="formValid === false"
          class="ma-0"
          dense
          type="error"
          :text="$t('general.formNotValid')"
        />
        <v-divider />
        <v-card-actions>
          <small class="pl-3">* {{ $t('general.requiredHint') }}</small>
          <v-spacer />
          <v-btn
            color="primary"
            text
            rounded
            @click.native="$emit('update:dialog-state', false)"
          >
            {{ $t('general.close') }}
          </v-btn>
          <v-btn
            v-show="!success && !error && !disableAction"
            color="primary"
            :disabled="!formValid"
            text
            rounded
            :loading="loading"
            @click.native="$emit('form:submit')"
          >
            {{ $t('general.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Alert from '@/components/alerts/Alert'
import FullScreenAlert from '@/components/alerts/FullScreenAlert'

export default {
  name: 'CreateModalLayout',
  components: {
    Alert,
    FullScreenAlert
  },
  props: {
    loading: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    mode: { type: String, default: 'create' },
    formValid: { type: Boolean, default: null },
    dialogState: { type: Boolean, default: false },
    i18nContext: { type: String, default: '' },
    disableAction: { type: Boolean, default: false }
  },
  computed: {
    title () {
      return this.mode === 'edit' ? 'editTitle' : 'createTitle'
    },
    titleBackgroundClasses () {
      return this.mode === 'create' ? '' : 'orange lighten-4'
    },
    titleClasses () {
      return this.mode === 'create' ? 'text-h5' : 'text-h5 orange--text text--darken-4'
    },
    showDivider () {
      return this.mode === 'create'
    }
  },
  watch: {
    success: function (success) {
      if (success) {
        setTimeout(function () {
          this.$emit('update:dialog-state', false)
        }.bind(this), 700)
      }
    }
  }
}
</script>
