<template>
  <v-alert
    text
    outlined
    :dense="dense"
    :type="type"
    transition="slide-x-transition"
  >
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: { type: String, default: '' },
    text: { type: String, default: '' },
    dense: { type: Boolean, default: false }
  }
}
</script>
